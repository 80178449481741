import $, { each } from "jquery";

const ready = function() {
  if($('.prev-next-nav-data').length) {
    $('.projects-next-prev-on-mobile').addClass('visible');

    const currentUrl = $('.prev-next-nav-data').data('path');

    let navLinks = [];
    let imgLinks = [];
    let currentLinkIndex;

    $('.prev-next-nav-data').find('.publication-promo').each(function(i){
      navLinks[i] = $(this).attr('href');
      imgLinks[i] = $(this).find('img').attr('src');
      if (navLinks[i] == currentUrl) {
        currentLinkIndex = i;
      }      
    })

    const prevLink = navLinks[currentLinkIndex-1];
    const prevImgLink = imgLinks[currentLinkIndex-1];
    if (prevLink) {
      $('.consecutive-nav .prev').addClass('visible')
      $('.consecutive-nav .prev').attr('href', prevLink)
      $('.consecutive-nav .prev .pic').attr('style', `background-image: url(${prevImgLink})`)
      
      $('.tag-list .prev').addClass('visible')
      $('.tag-list .prev a').attr('href', prevLink)
    } else {
      $('.consecutive-nav .prev').css('pointer-events', 'none')
    }

    const nextLink = navLinks[currentLinkIndex+1];
    const nextImgLink = imgLinks[currentLinkIndex+1];
    if (nextLink) {
      $('.consecutive-nav .next').addClass('visible')
      $('.consecutive-nav .next').attr('href', nextLink)
      $('.consecutive-nav .next .pic').attr('style', `background-image: url(${nextImgLink})`)

      $('.tag-list .next').addClass('visible')
      $('.tag-list .next a').attr('href', nextLink)
      
      $('.next-project-nav').find('.publication-promo').addClass('project-theme-'+navLinks[currentLinkIndex+1].split("/").pop());
      $('.next-project-nav').find('.publication-promo').eq(currentLinkIndex+1).css('display', 'block')
      
      $('.next-project-nav').find('.all-projects-page-link').addClass('project-theme-'+navLinks[currentLinkIndex+1].split("/").pop());
    } else {
      $('.consecutive-nav .next').css('pointer-events', 'none')
    }

  }

  
};

$(document).ready(ready);
