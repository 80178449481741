import $ from "jquery";

import "../styles/main.scss";
import "./awakeness";
import "./scroll-link";
import "./vimeo-scaler";
import "./float-header";
// import "./slick.min";
// import "./slick";
import "./nav-mobile";
import "./prev-next-nav";
import "./video-hover";
import "./hide-logo";
import "./main-logo-zoom";
import "./scroll-change";


import { registerPlayer } from "./plyr";

function ready() {
  registerPlayer("#initializePlyr", ".plyr-player");
}

window.addEventListener("DOMContentLoaded", ready);