import $, { each } from "jquery";

const ready = function() {
  if ($(".floating-header").length) {
    floatingHeader();
    window.addEventListener("scroll", floatingHeader);
  }
  
};
const floatingHeader = () => {

  let extraOffset = $(window).scrollTop() * 2;
  $(".floating-header").css('transform', `translateY(${extraOffset*-.3}px)`);
  
};

$(document).ready(ready);


