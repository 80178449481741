import $, { each } from "jquery";

const ready = function() {
  if($('span.logo').length) {

    const logoZoom = () => {

      if ($(window).scrollTop() > 0) {
        $('span.logo').addClass('normalized')
      } else {
        $('span.logo').removeClass('normalized')
      }
      
    };
    logoZoom();
    window.addEventListener("scroll", logoZoom);
  }
};

$(document).ready(ready);
