import $ from 'jquery';
import { yall } from "yall-js";

yall({
  observeChanges: true,
  events: {
    canplaythrough: function (event) {
      event.target.classList.add("yall-loaded");
    }
  }
});

const ready = function() {


  const observer = new IntersectionObserver(playAndPauseVideos, {threshold: 0})
  const figures = [...document.querySelectorAll('.project-promo'),
  ...document.querySelectorAll('.autoplay')];


  if (!figures.length) return

  var videos = [];

  for (let figure of figures) {
    videos.push(figure.querySelector('video'))
  }


  if (itIsMobileDevice()) {
    for (let figure of figures) observer.observe(figure)
  } else {    
    figures.forEach((item, index) => {
      item.addEventListener('mouseover', () => playVideo(videos[index]), false);
      item.addEventListener('mouseout', () => pauseVideo(videos[index]), false);        
    });
  }
  
  function playVideo(video) {
    if (!video) return

    if (video.readyState === 4) {
        video.play()
        return
    } 

    video.onloadeddata = () => video.play()
  }
  
  function pauseVideo(video) {
    if (!video) return

    if (video.readyState === 4) return video.pause(); 
    video.onloadeddata = () => video.pause()
  }

  function playAndPauseVideos(entries, observer) {
    entries.forEach((entry) => {
      let video = entry.target.querySelector('video')

      entry.isIntersecting ? playVideo(video) : pauseVideo(video)
    });
  }
  
};

function itIsMobileDevice() {
  return (window.innerWidth <= 768);
}

$(document).ready(ready);