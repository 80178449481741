import $ from 'jquery';


const ready = function() {
  $('.project-promo').on('mouseenter', function(e) {
    
    let hideOnHoverLogo = $(this).attr('data-hide-on-hover');
    
    if(hideOnHoverLogo) {
      $(hideOnHoverLogo).addClass('hidden');
    }
  }).on('mouseleave', function(e) {
    
    let hideOnHoverLogo = $(this).attr('data-hide-on-hover');
    
    if(hideOnHoverLogo) {
      $(hideOnHoverLogo).removeClass('hidden');
    }
  });
};

$(document).ready(ready);